import React from "react";
import Hero from "../Hero/Hero";

const HeroLanding = ({pageTitle, title, description, useBgPattern, bgImage, extended, theme}) => {
    return (
        <Hero
            pageTitle={pageTitle}
            title={title}
            description={description}
            useBgPattern={useBgPattern}
            bgImage={bgImage}
            extended={extended}
            type="landing"
            theme={theme}
        />
    )
};

export default HeroLanding;